<template>
  <div class="page-content">
    <div class="content">
      <div>
        <p>{{ $t('jian-jie-zai-zhe-li-wo-men-jiang-xiang-nin-jie-shao-mai-jia-ping-fen-xi-tong-ji-qi-sheng-cheng-he-shi-yong-fang-shi') }}</p>
        <p>{{ $t('xiang-guan-wang-zhan-appname', [appName]) }}</p>
        <p>{{ $t('xiang-guan-fang-suo-you-mai-jia') }}</p>
      </div>
      <div>
        <h2 class="title">{{ $t('1-ru-he-jin-ru-mai-jia-ping-fen-xi-tong') }}</h2>
        <p>
          {{ $t('mai-jia-ping-fen-xi-tong-gu-li-mai-jia-bao-chi-gao-fu-wu-biao-zhun-wei-wo-men-de-mai-jia-chuang-zao-liang-hao-de-gou-wu-ti-yan') }} </p>
        <p>{{ $t('wei-da-dao-cheng-nuo-mu-biao-jiang-kou-fen') }}</p>
        <p>{{ $t('nin-ke-yi-zai-mai-jia-zhong-xin') }}{{ $t('greater-than') }}{{ $t('greater-than-0') }}{{ $t('mai-jia-xin-yong-ping-fen-ye-mian-cha-kan-nin-de-ping-fen') }}</p>
      </div>
      <div>
        <h2 class="title">{{ $t('2-ping-fen-shi-ru-he-chan-sheng-de') }}</h2>
        <div>
          <p>
            {{ $t('jin-dang-man-zu-mai-fang-de-zui-di-qi-wang-shi-cai-hui-shou-qu-ji-fen-tong-shi-zhe-ye-hui-ti-xing-mai-jia-yi-xie-wen-ti-huan-xu-yao-gai-jin') }} </p>
          <p>
            {{ $t('mai-jia-ping-fen-xi-tong-hui-zai-mei-ge-yue-de-di-yi-tian-gen-ju-mai-jia-shang-yue-de-wei-gui-qing-kuang-geng-xin-ping-fen-nin-ke-yi-dian-ji-dui-ying-de-ping-fen-wei-du-guan-jian-ci-cha-kan-wan-zheng-de-zheng-ce') }} </p>
          <div
            class="el-table el-table--fit el-table--border el-table--enable-row-hover el-table--enable-row-transition"
            style="width: 100%; margin-top: 20px"
          >
            <div class="hidden-columns">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="el-table__header-wrapper">
              <table
                cellspacing="0"
                cellpadding="0"
                border="0"
                class="el-table__header"
                style="width: 850px"
              >
                <colgroup>
                  <col name="el-table_3_column_11" width="240" />
                  <col name="el-table_3_column_12" width="410" />
                  <col name="el-table_3_column_13" width="200" />
                  <col name="gutter" width="0" />
                </colgroup>
                <thead class="has-gutter">
                  <tr class="">
                    <th
                      colspan="1"
                      rowspan="1"
                      class="el-table_3_column_11 is-leaf el-table__cell"
                    >
                      <div class="cell">{{ $t('ji-fen-ce-liang') }}</div>
                    </th>
                    <th
                      colspan="1"
                      rowspan="1"
                      class="el-table_3_column_12 is-leaf el-table__cell"
                    >
                      <div class="cell">{{ $t('gui-ge-0') }}</div>
                    </th>
                    <th
                      colspan="1"
                      rowspan="1"
                      class="el-table_3_column_13 is-leaf el-table__cell"
                    >
                      <div class="cell">{{ $t('mei-yue-kou-fen') }}</div>
                    </th>
                    <th
                      class="el-table__cell gutter"
                      style="width: 0px; display: none"
                    ></th>
                  </tr>
                </thead>
              </table>
            </div>
            <div class="el-table__body-wrapper is-scrolling-none">
              <table
                cellspacing="0"
                cellpadding="0"
                border="0"
                class="el-table__body"
                style="width: 850px"
              >
                <colgroup>
                  <col name="el-table_3_column_11" width="240" />
                  <col name="el-table_3_column_12" width="410" />
                  <col name="el-table_3_column_13" width="200" />
                </colgroup>
                <tbody>
                  <tr class="el-table__row">
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_3_column_11 el-table__cell"
                    >
                      <div class="cell">{{ $t('bu-lv-hang-shuai-nfr') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_3_column_12 el-table__cell"
                    >
                      <div class="cell">
                        {{ $t('jin-7-tian-mai-jia-qu-xiao-huo-tui-huo-de-ding-dan-shu-liang-zhan-ding-dan-zong-shu-de-30') }} </div>
                    </td>
                    <td
                      rowspan="2"
                      colspan="1"
                      class="el-table_3_column_13 el-table__cell"
                    >
                      <div class="cell">{{ $t('zui-duo-10-fen') }}</div>
                    </td>
                  </tr>
                  <tr class="el-table__row">
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_3_column_11 el-table__cell"
                    >
                      <div class="cell">{{ $t('chi-fa-shuai-lsr') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_3_column_12 el-table__cell"
                    >
                      <div class="cell">
                        {{ $t('jin-7-tian-nei-mai-jia-yan-chi-72-xiao-shi-yi-shang-de-ding-dan-shu-liang-zhan-ding-dan-zong-shu-de-20') }} </div>
                    </td>
                    <td
                      rowspan="2"
                      colspan="1"
                      class="el-table_3_column_13 el-table__cell"
                    >
                      <div class="cell"></div>
                    </td>
                  </tr>
                  <tr class="el-table__row">
                    <td
                      rowspan="2"
                      colspan="1"
                      class="el-table_3_column_11 el-table__cell"
                    >
                      <div class="cell">{{ $t('ke-hu-fu-wu') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_3_column_12 el-table__cell"
                    >
                      <div class="cell">{{ $t('cu-lu-huo-ru-ma-xing-de-liao-tian-huo-ping-lun') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_3_column_13 el-table__cell"
                    >
                      <div class="cell">{{ $t('zui-duo-20-fen') }}</div>
                    </td>
                  </tr>
                  <tr class="el-table__row">
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_3_column_12 el-table__cell"
                    >
                      <div class="cell">{{ $t('yao-qiu-mai-jia-ben-yue-qu-xiao-ding-dan-chao-guo-5-ci') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_3_column_13 el-table__cell"
                    >
                      <div class="cell">{{ $t('zui-duo-10-fen-0') }}</div>
                    </td>
                  </tr>
                  <tr class="el-table__row">
                    <td
                      rowspan="2"
                      colspan="1"
                      class="el-table_3_column_11 el-table__cell"
                    >
                      <div class="cell">{{ $t('bu-shou-nuo-yan') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_3_column_12 el-table__cell"
                    >
                      <div class="cell">{{ $t('mai-jia-hui-fu-shuai-di-80') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_3_column_13 el-table__cell"
                    >
                      <div class="cell">{{ $t('zui-duo-10-fen-1') }}</div>
                    </td>
                  </tr>
                  <tr class="el-table__row">
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_3_column_12 el-table__cell"
                    >
                      <div class="cell">
                        {{ $t('mai-fang-wei-an-cheng-nuo-xiang-mai-fang-ti-gong-fu-wu-sun-hai-mai-fang-quan-yi') }} </div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_3_column_13 el-table__cell"
                    >
                      <div class="cell">{{ $t('zui-duo-10-fen-2') }}</div>
                    </td>
                  </tr>
                  <!---->
                </tbody>
              </table>
              <!----><!---->
            </div>
            <!----><!----><!----><!---->
            <div
              class="el-table__column-resize-proxy"
              style="display: none"
            ></div>
          </div>
        </div>
        <div>
          <h2 class="title">{{ $t('3-yu-yi-ding-ji-fen-xiang-guan-de-cheng-fa-shi-shi-mo') }}</h2>
          <div
            class="el-table el-table--fit el-table--border el-table--enable-row-hover el-table--enable-row-transition"
            style="width: 100%; margin-top: 20px"
          >
            <div class="hidden-columns">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="el-table__header-wrapper">
              <table
                cellspacing="0"
                cellpadding="0"
                border="0"
                class="el-table__header"
                style="width: 1134px"
              >
                <colgroup>
                  <col name="el-table_4_column_14" width="180" />
                  <col name="el-table_4_column_15" width="159" />
                  <col name="el-table_4_column_16" width="159" />
                  <col name="el-table_4_column_17" width="159" />
                  <col name="el-table_4_column_18" width="159" />
                  <col name="el-table_4_column_19" width="159" />
                  <col name="el-table_4_column_20" width="159" />
                  <col name="gutter" width="0" />
                </colgroup>
                <thead class="has-gutter">
                  <tr class="">
                    <th
                      colspan="1"
                      rowspan="1"
                      class="el-table_4_column_14 is-leaf el-table__cell"
                    >
                      <div class="cell">{{ $t('zong-ji-fen') }}</div>
                    </th>
                    <th
                      colspan="1"
                      rowspan="1"
                      class="el-table_4_column_15 is-center is-leaf el-table__cell"
                    >
                      <div class="cell">5</div>
                    </th>
                    <th
                      colspan="1"
                      rowspan="1"
                      class="el-table_4_column_16 is-center is-leaf el-table__cell"
                    >
                      <div class="cell">10</div>
                    </th>
                    <th
                      colspan="1"
                      rowspan="1"
                      class="el-table_4_column_17 is-center is-leaf el-table__cell"
                    >
                      <div class="cell">15</div>
                    </th>
                    <th
                      colspan="1"
                      rowspan="1"
                      class="el-table_4_column_18 is-center is-leaf el-table__cell"
                    >
                      <div class="cell">20</div>
                    </th>
                    <th
                      colspan="1"
                      rowspan="1"
                      class="el-table_4_column_19 is-center is-leaf el-table__cell"
                    >
                      <div class="cell">25</div>
                    </th>
                    <th
                      colspan="1"
                      rowspan="1"
                      class="el-table_4_column_20 is-center is-leaf el-table__cell"
                    >
                      <div class="cell">{{ $t('greater-than-1') }};25</div>
                    </th>
                    <th
                      class="el-table__cell gutter"
                      style="width: 0px; display: none"
                    ></th>
                  </tr>
                </thead>
              </table>
            </div>
            <div class="el-table__body-wrapper is-scrolling-none">
              <table
                cellspacing="0"
                cellpadding="0"
                border="0"
                class="el-table__body"
                style="width: 1134px"
              >
                <colgroup>
                  <col name="el-table_4_column_14" width="180" />
                  <col name="el-table_4_column_15" width="159" />
                  <col name="el-table_4_column_16" width="159" />
                  <col name="el-table_4_column_17" width="159" />
                  <col name="el-table_4_column_18" width="159" />
                  <col name="el-table_4_column_19" width="159" />
                  <col name="el-table_4_column_20" width="159" />
                </colgroup>
                <tbody>
                  <tr class="el-table__row">
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_14 el-table__cell"
                    >
                      <div class="cell">{{ $t('chu-fa-ji-bie') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_15 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('1-deng-ji') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_16 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('2-deng-ji') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_17 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('3-deng-ji') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_18 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('4-deng-ji') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_19 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('5-deng-ji') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_20 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('6-deng-ji') }}</div>
                    </td>
                  </tr>
                  <tr class="el-table__row">
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_14 el-table__cell"
                    >
                      <div class="cell">{{ $t('jin-zhi-ying-xiao-huo-dong-28-tian') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_15 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_16 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-0') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_17 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-1') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_18 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-2') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_19 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-3') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_20 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-4') }}</div>
                    </td>
                  </tr>
                  <tr class="el-table__row">
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_14 el-table__cell"
                    >
                      <div class="cell">{{ $t('shan-chu-mian-yun-fei-huo-yun-fei-hui-kou-28-tian') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_15 is-center el-table__cell"
                    >
                      <div class="cell"></div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_16 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-5') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_17 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-6') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_18 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-7') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_19 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-8') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_20 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-9') }}</div>
                    </td>
                  </tr>
                  <tr class="el-table__row">
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_14 el-table__cell"
                    >
                      <div class="cell">{{ $t('deboost-shang-shi-28-tian') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_15 is-center el-table__cell"
                    >
                      <div class="cell"></div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_16 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-10') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_17 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-11') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_18 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-12') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_19 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-13') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_20 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-14') }}</div>
                    </td>
                  </tr>
                  <tr class="el-table__row">
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_14 el-table__cell"
                    >
                      <div class="cell">{{ $t('xian-zhi-mai-fang-dai-kuan-28-tian') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_15 is-center el-table__cell"
                    >
                      <div class="cell"></div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_16 is-center el-table__cell"
                    >
                      <div class="cell"></div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_17 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-15') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_18 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-16') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_19 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-17') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_20 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-18') }}</div>
                    </td>
                  </tr>
                  <tr class="el-table__row">
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_14 el-table__cell"
                    >
                      <div class="cell">{{ $t('zu-zhi-lie-biao-chuang-jian-he-bian-ji-28-tian') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_15 is-center el-table__cell"
                    >
                      <div class="cell"></div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_16 is-center el-table__cell"
                    >
                      <div class="cell"></div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_17 is-center el-table__cell"
                    >
                      <div class="cell"></div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_18 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-19') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_19 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-20') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_20 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-21') }}</div>
                    </td>
                  </tr>
                  <tr class="el-table__row">
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_14 el-table__cell"
                    >
                      <div class="cell">{{ $t('dong-jie-zhang-hu-28-tian') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_15 is-center el-table__cell"
                    >
                      <div class="cell"></div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_16 is-center el-table__cell"
                    >
                      <div class="cell"></div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_17 is-center el-table__cell"
                    >
                      <div class="cell"></div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_18 is-center el-table__cell"
                    >
                      <div class="cell"></div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_19 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-22') }}</div>
                    </td>
                    <td
                      rowspan="1"
                      colspan="1"
                      class="el-table_4_column_20 is-center el-table__cell"
                    >
                      <div class="cell">{{ $t('key-23') }}</div>
                    </td>
                  </tr>
                  <!---->
                </tbody>
              </table>
              <!----><!---->
            </div>
            <!----><!----><!----><!---->
            <div
              class="el-table__column-resize-proxy"
              style="display: none"
            ></div>
          </div>
        </div>
        <div>
          <h2 class="title">{{ $t('4-ke-hu-fu-wu-gui-ze-ji-shi-shi-xi-ze') }}</h2>
          <p>{{ $t('shi-mo-shi-cu-lu-huo-ru-ma-xing-de-liao-tian-huo-ping-lun') }}</p>
          <p>
            {{ $t('e-yi-sao-rao-shi-zhi-hui-yuan-dui-ta-ren-jin-hang-wu-ru-zu-zhou-wei-xie-ping-lun-deng-yan-yu-gong-ji-huo-cai-qu-bu-zheng-dang-shou-duan-sao-rao-ta-ren-sun-hai-ta-ren-he-fa-quan-yi-de-hang-wei') }} </p>
          <p>{{ $t('ru-he-chu-li-cu-lu-huo-ru-ma-xing-de-liao-tian-huo-ping-lun') }}</p>
          <p>{{ $t('dui-ye-wu-quan-xian-de-yi-ban-he-yan-zhong-wei-gui-guan-li-he-kong-zhi-jin-hang-kou-fen') }}</p>
          <p>
            {{ $t('shi-yong-qing-xing-eyi-sao-rao-shi-zhi-hui-yuan-dui-ta-ren-jin-hang-ru-ma-zu-zhou-wei-xie-deng-yan-yu-gong-ji-huo-zhe-yielie-shou-duan-sao-rao-ta-ren-sun-hai-ta-ren-he-fa-quan-yi-de-hang-wei') }} </p>
        </div>
        <div>
          <h2 class="title">{{ $t('5-wei-yue-shi-yong-de-cuo-shi') }}</h2>
          <p>{{ $t('shi-mo-shi-wei-yue') }}</p>
          <p>
            {{ $t('wei-yue-shi-zhi-mai-jia-wei-xiang-mai-jia-ti-gong-fu-wu-qin-fan-mai-jia-quan-yi-wei-an-cheng-nuo-xiang-appname-lv-hang-yi-wu-mai-fang-bi-xu-ji-xu-lv-hang-fa-ding-huo-yue-ding-de-geng-huan-tui-huo-he-tui-kuan', [appName]) }} </p>
          <p>{{ $t('shi-yong-cuo-shi-kou-fen') }}</p>
          <p>{{ $t('ju-ti-cuo-shi') }}</p>
          <p>
            {{ $t('1-ru-mai-jia-zai-te-ding-qing-kuang-xia-dui-yi-fu-kuan-ding-dan-huo-xiang-ying-shang-pin-huo-fu-wu-huan-you-qi-ta-xu-yao-lv-hang-de-cheng-nuo-mei-xiang-yi-ban-wei-gui-kou-5-fen') }} </p>
          <p>{{ $t('2-mai-jia-wei-fan-yi-xia-cheng-nuo-zhi-yi-mei-yan-zhong-yi-ci-kou-10-fen') }}</p>
          <p>
            {{ $t('1-appname-pan-duan-mai-jia-ying-cheng-dan-tui-kuan-deng-shou-hou-bao-zhang-ze-ren-mai-jia-ju-bu-cheng-dan', [appName]) }} </p>
          <p>
            {{ $t('2-appname-pan-duan-mai-jia-que-shi-ying-cheng-dan-7-tian-wu-li-you-tui-huan-huo-de-shou-hou-bao-zhang-ze-ren-dan-mai-jia-ju-bu-cheng-dan', [appName]) }} </p>
          <p>{{ $t('3-wei-jing-mai-mai-shuang-fang-xie-shang-ju-jue-huo-yan-chi-xiang-mai-jia-fa-song-cheng-nuo-de-shi-yong-shang-pin') }}</p>
          <p>{{ $t('4-mai-jia-zai-zhi-fu-ding-dan-hou-72-xiao-shi-nei-wei-chu-li-ding-dan') }}</p>
          <p>
            {{ $t('can-jia-appname-guan-fang-huo-dong-de-mai-jia-wei-neng-wan-cheng-huo-dong-yao-qiu-fa-huo-shi-jian-chu-wai-wei-fan-appname-guan-fang-fa-bu-de-qi-ta-guan-li-nei-rong-bao-kuo-dan-bu-xian-yu-gui-ze-gui-fan-lei-mu-guan-li-gui-fan-hang-ye-biao-zhun-gui-ding-shuo-ming-fu-he-ju-ti-qing-kuang-de-cong-qi-gui-ding', [appName, appName]) }} </p>
        </div>
        <div>
          <h2 class="title">{{ $t('6-chu-fa-hui-chi-xu-duo-jiu') }}</h2>
          <p>
            {{ $t('chu-fa-jiang-zai-28-tian-hou-jie-shu-zhi-yao-cai-qu-xiang-ying-cuo-shi-gai-shan-xian-you-dian-pu-ye-ji-bing-bao-chi-da-biao-bei-zhi-cai-de-mai-jia-jiang-zhong-xin-huo-de-yi-ding-de-mai-jia-quan-li') }} </p>
        </div>
        <div>
          <h2 class="title">{{ $t('7-wei-gui-chu-li') }}</h2>
          <p>
            {{ $t('1-ru-guo-qing-kuang-yi-ban-xiao-fei-zhe-fa-qi-tou-su-appname-pan-duan-tou-su-you-li-yi-ban-wei-gui-mei-ci-kou-5-fen-wan-cheng-5-ge-ding-dan-ke-hui-fu-5-dian', [appName]) }} </p>
          <p>
            {{ $t('2-qing-jie-yan-zhong-de-xiao-fei-zhe-tou-su-appname-pan-duan-tou-su-you-li-qing-jie-yan-zhong-de-mei-xiang-kou-20-fen-wan-cheng-20-ge-ding-dan-ke-hui-fu-20-dian', [appName]) }} </p>
          <p>{{ $t('3-qing-jie-yan-zhong-de-dong-jie-zhang-hao-28-ri-nei-guan-bi-dian-pu') }}</p>
          <p>
            <span>{{ $t('li-1-mai-jiaazai-di-3-zhou-huo-de-3-fen-jiang-zai-di-7-zhou-zhong-xin-huo-de-quan-li') }}</span>
          </p>
          <img :src="shippingUrl1" alt="" />
          <p>
            <span
              >{{ $t('li-2-mai-jiabzai-di-3-zhou-huo-de-3-fen-di-5-zhou-huo-de-3-fen-zhe-xie-ji-fen-hui-lei-ji-qi-lai-ding-yi-deng-ji-di-9-zhou-hui-duo-hui-quan-li') }}利</span
            >
          </p>
          <img :src="shippingUrl2" alt="" />
          <p>
            <span
              >{{ $t('li-3-mai-jiacxin-ji-du-kai-shi-qian-yi-zhou-huo-de-3-fen-ji-du-kai-shi-hou-huo-de-3-fen-xin-huo-de-de-ji-fen-jiang-zhong-xin-ding-yi-deng-ji') }}</span
            >
          </p>
          <img :src="shippingUrl3" alt="" />
        </div>
        <div>
          <h2 class="title">{{ $t('8-ru-he-jin-hang-ping-fen-shen-su') }}</h2>
          <p>
            {{ $t('dan-ji-qiu-zhu-re-xian-lian-jie-jin-hang-shen-su-ru-guo-nin-shen-su-cheng-gong-wo-men-jiang-qu-xiao-nin-de-kou-fen-bing-hui-fu-nin-xiang-ying-de-mai-jia-quan-li') }} </p>
        </div>
        <div>
          <h2 class="title">
            {{ $t('9-xin-dian-pu-xiang-you-mian-fei-de-30-tian-de-shang-pin-zhan-xiao-qi-zhan-xiao-qi-jian-ping-tai-hui-wei-xin-dian-pu-de-suo-you-shang-pin-jin-hang-mian-fei-tui-guang') }} </h2>
        </div>
        <div>
          <h2 class="title">{{ $t('10-zui-zhong-jie-shi-quan-gui-ben-gong-si-suo-you') }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      shippingUrl1: require('@/assets/imgs/shipping1.png'),
      shippingUrl2: require('@/assets/imgs/shipping2.png'),
      shippingUrl3: require('@/assets/imgs/shipping3.png')
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    }
  }
}
</script>
